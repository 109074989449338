@import url('https://fonts.googleapis.com/css2?family=Montserrat:wght@100;200&display=swap');

.container {
  height: 100vh;
  width: 100vw;
  display: flex;
  flex-direction: column;
  background-color: rgb(235, 235, 235);

}


.navBar {

  display: flex;
  align-items: center;
  font-family: 'Montserrat', sans-serif;
  font-weight: 700;
  color: white;
  background-color: rgb(36, 36, 36);
  width: 100%;
  height: 10%;
}

.subNavBar{
  border-top: 1px solid #4F4F4F;
 font-size: 19px;
  display: flex;
  align-items: center;
  text-align: center;
  color: white;
  background-color: rgb(36, 36, 36);
  width: 100%;
  height: 5%;
}


.listaNav {
  padding-left: 100px;
  list-style-type: none;
  display: flex;
  flex-direction: row;
  justify-content: space-around;
  width: 100vw;

}


.listaSubNav{
  font-family: 'Montserrat', sans-serif;
  list-style-type: none;
display: flex;
flex-direction: row;
justify-content: space-around;
width: 100vw;
  
}


.divLogo {
  width: 50%;
  display: flex;
  justify-content: flex-start;
  padding-left: 40px;
}

.listNav {
  display: flex;
  justify-content: space-around;
  width: 50%;
}

.box_1{
  position: relative;
  height: 100vh;
  display: flex;

  justify-content: center;
  }


  .box_1{ 
    /* imagem */
    background-image: url('fundo.jpg');
   padding-bottom: 200px;
    background-attachment: fixed;
    background-size: cover;
    background-position: center;
    height: 100vh;
    display: flex;
   
    justify-content: center;
    position: relative;
  }

  .box_1::before{
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    height: 100%;
    width: 100%;
    background-color: rgba(0, 0, 0, 0.5);
  }
  h1 {
    color: #fff;
    font-size: 5rem;
    text-align: center;
  }
  

  p {
    padding-bottom: 15px;
    border-bottom: 1px solid #4F4F4F;
    width: 70vw;
    color: #fff;
    font-size: 2rem;
    text-align: center;
  }
  h1 {
    color: #fff;
    font-size: 5rem;
    text-align: center;
    position: relative;
    z-index: 1;
  }
  h2{
    color: #fff;
    font-size: 3rem;
    text-align: center;
    position: relative;
    z-index: 1;
  }

li{
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 2px;
}   

.overlay{
  position: absolute;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  z-index: 1;
}

.conteudo{
  position: relative;
  z-index: 2;
}
.footer{
  display: flex;
  justify-content: center;
  text-align: center  ;
}

